import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "iframe-guide",
      "style": {
        "position": "relative"
      }
    }}>{`Iframe Guide`}<a parentName="h1" {...{
        "href": "#iframe-guide",
        "aria-label": "iframe guide permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}>{`Overview`}<a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Using TaleFin's iframe endpoint you can quickly and easily integrate your existing systems with TaleFin's solutions.`}</p>
    <p>{`One-line example HTML:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}" />`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`As the title of this guide suggests this endpoint is intended to be used through an iframe embedded in your page,
however the URL can also be sent as a link directly to clients via e-mail, SMS or any text-based messaging system you already integrate with.`}</p>
    <h2 {...{
      "id": "required-parameters",
      "style": {
        "position": "relative"
      }
    }}>{`Required Parameters`}<a parentName="h2" {...{
        "href": "#required-parameters",
        "aria-label": "required parameters permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The following URL paramaters are required, if they are missing or incorrect the client will receive a "Page Not Found" error:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`VENDOR_LABEL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label that uniquely identifies your vendor account.`}<br />{` Provided to you by TaleFin when your account is provisioned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`VENDOR_SPECIFIC_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID that uniquely identifies the active client.`}<br />{` This is used primarily for correlating data you receive from TaleFin against your own system's records, generally the UUID for this client in your own system would be used.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "requesting-multiple-institutions",
      "style": {
        "position": "relative"
      }
    }}>{`Requesting Multiple Institutions`}<a parentName="h2" {...{
        "href": "#requesting-multiple-institutions",
        "aria-label": "requesting multiple institutions permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`By default, we only collect one institution per application. This behaviour can be overridden by specifying `}<inlineCode parentName="p">{`multiple`}</inlineCode>{` query parameter to be true.`}</p>
    <p>{`For example, in order to request multiple institutions for the client, you may use something like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}?multiple=true" />`}</code>{`
        `}</deckgo-highlight-code>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Flag`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`multiple=True`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "customising-collection-services",
      "style": {
        "position": "relative"
      }
    }}>{`Customising Collection Services`}<a parentName="h2" {...{
        "href": "#customising-collection-services",
        "aria-label": "customising collection services permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`By default, only the analysis option is enabled when creating a profile. This behaviour can be overridden by specifying `}<inlineCode parentName="p">{`collection`}</inlineCode>{` query parameters. When including a `}<inlineCode parentName="p">{`collection`}</inlineCode>{` query parameter in your iframe URL, only the specified services will be enabled.`}</p>
    <p>{`For example, in order to only request account owner information for the client, you may use something like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}?collect=account_owner" />`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In order to request multiple services, simply specify the collect flag again. For example, in order to request an analysis in addition to account owner information, you may use something like:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}?collect=account_owner&collect=analysis" />`}</code>{`
        `}</deckgo-highlight-code>
    <p><strong parentName="p">{`The account selection feature is only available on the v2 iFrame.`}</strong>{` This will provide the user with a set of checkboxes to select which accounts should be crawled before submitting the application.`}</p>
    <p>{`The full list of options is available below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Flag`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account Selection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=select_accounts`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Balances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=balances`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=transactions`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interims`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=interims`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Estatements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=estatements`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=analysis`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account Owner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`collect=account_owner`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "automatic-redirecting",
      "style": {
        "position": "relative"
      }
    }}>{`Automatic Redirecting`}<a parentName="h2" {...{
        "href": "#automatic-redirecting",
        "aria-label": "automatic redirecting permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><strong parentName="p">{`The automatic redirection feature is only available on the v2 iFrame.`}</strong>{` This will send the user to the designated URL depending on the outcome of the application.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Flag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`success_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination to send the user to after a `}<inlineCode parentName="td">{`COMPLETED`}</inlineCode>{` crawl.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failure_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination to send the user to after a `}<inlineCode parentName="td">{`FAILED`}</inlineCode>{` or `}<inlineCode parentName="td">{`DIED`}</inlineCode>{` crawl.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "branding",
      "style": {
        "position": "relative"
      }
    }}>{`Branding`}<a parentName="h2" {...{
        "href": "#branding",
        "aria-label": "branding permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><strong parentName="p">{`This feature has been migrated from query string parameters to vendor endpoint fields. Provide your desired values and our developers will configure your vendor profile to match.`}</strong></p>
    <p>{`The user interface can be configured to match your branding by specifying the query string parameters shown in the table below.`}</p>
    <p>{`Colours must be specified in hex format, without the leading hash/pound sign `}<inlineCode parentName="p">{`#`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`colorPrimary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#FFB000`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary branding colour - used as highlights for icons, buttons and links.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`colorSecondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#F9F9F9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary branding colour - used as the background of the shaded top bar.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`colorBackground`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#FFFFFF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour - used as the general background across all pages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`font`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`DM+Sans`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Branding font - any font available on `}<a parentName="td" {...{
              "href": "https://fonts.google.com/"
            }}>{`Google Fonts`}</a>{` can be specified by name and will be dynamically downloaded and used.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "prefilling-details",
      "style": {
        "position": "relative"
      }
    }}>{`Prefilling Details`}<a parentName="h2" {...{
        "href": "#prefilling-details",
        "aria-label": "prefilling details permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The client's personal details can be pre-filled with data from your own system via the following query string parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client's first name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client's last name (including any middle names)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phoneNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client's phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client's email address`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For example:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}?firstName=John&lastName=Citizen&email=test@banks.talefin.com&phoneNumber=0400000000" />`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "deep-linking",
      "style": {
        "position": "relative"
      }
    }}>{`Deep Linking`}<a parentName="h2" {...{
        "href": "#deep-linking",
        "aria-label": "deep linking permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Parts of the process can be skipped by deep-linking with any required information provided via query string parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Path`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Outcome`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Standard application flow, beginning with asking for client's details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}/banks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Skip client details & informational prelude steps, going directly to the bank selection step.`}<br /><strong parentName="td">{`REQUIRED`}</strong>{`: Customer Details must be provided via query string paramaters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}?bank={bank_id}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Skip client details and prelude steps, and prefill the iFrame with the designated bank. (Bank ID currently differs on production and staging environments)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}>{`Events`}<a parentName="h2" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The iframe emits `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage"
      }}>{`postMessage`}</a>{`
events in response to certain actions, you can listen to these events in order to enable
your own website or system to react to changes in the ongoing TaleFin application.`}</p>
    <p>{`Each event will contain an `}<inlineCode parentName="p">{`event.data.type`}</inlineCode>{` field which specifies the type of event being fired,
from this you can determine your desired behaviour for that given event.`}</p>
    <h4 {...{
      "id": "note",
      "style": {
        "position": "relative"
      }
    }}>{`NOTE`}<a parentName="h4" {...{
        "href": "#note",
        "aria-label": "note permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p><strong parentName="p">{`DO NOT`}</strong>{` rely on these events or any client-side behaviour for important business logic
as their execution in the browser can never be guaranteed. Only webhooks should be used for
mission-critical processes.`}</p>
    <h3 {...{
      "id": "resizing",
      "style": {
        "position": "relative"
      }
    }}>{`Resizing`}<a parentName="h3" {...{
        "href": "#resizing",
        "aria-label": "resizing permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`An event of type `}<inlineCode parentName="p">{`resize`}</inlineCode>{` is fired when the content changes size, you can listen to this and
resize the iframe's external dimensions accordingly so the iframe automatically expands to
present all content, avoiding nested scrolling:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`window.addEventListener('message', function (event) {
  if (event.data.type === 'resize') {
    document.querySelector(
      'iframe#credfin',
    ).style = \`height: \${event.data.height}px\`;
  }
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In this case `}<inlineCode parentName="p">{`event.data`}</inlineCode>{` contains the following fields:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`type Resize = {
  height: number;
  width: number;
};`}</code>{`
        `}</deckgo-highlight-code>
    <h3 {...{
      "id": "navigation",
      "style": {
        "position": "relative"
      }
    }}>{`Navigation`}<a parentName="h3" {...{
        "href": "#navigation",
        "aria-label": "navigation permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`An event of type `}<inlineCode parentName="p">{`loaded`}</inlineCode>{` is fired when the client progresses to another
step which completes loading, generally in response to this you would scroll
them to the top of the page since navigating inside the iframe does not reset
their scroll to the top as a full page load usually does.`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`window.addEventListener('message', function (event) {
  if (event.data.type === 'loaded') {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In this case `}<inlineCode parentName="p">{`event.data`}</inlineCode>{` contains a single property `}<inlineCode parentName="p">{`page`}</inlineCode>{` which may be any value in the following enum type:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`enum Pages {
  loading = 'loading',
  userDetails = 'userDetails',
  prelude = 'prelude',
  banks = 'banks',
  crawler = 'crawler',
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3 {...{
      "id": "client-details",
      "style": {
        "position": "relative"
      }
    }}>{`Client Details`}<a parentName="h3" {...{
        "href": "#client-details",
        "aria-label": "client details permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`An event of type `}<inlineCode parentName="p">{`clientDetails`}</inlineCode>{` is fired when the client completes submission
of their personal details, this information can also be retrieved via the Profile API,
however to avoid unnecessary network requests you can simply listen to this event.`}</p>
    <p>{`Typically you would use these client details to personalize the presentation of your application form
or pre-fill/skip asking the client for this data again in your own subsequent account registration form.`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`window.addEventListener('message', function (event) {
  if (event.data.type === 'clientDetails') {
    document.querySelector('input.email').value = event.data.email;
  }
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In this case `}<inlineCode parentName="p">{`event.data`}</inlineCode>{` contains the following fields:`}</p>
    <deckgo-highlight-code {...{
      "language": "tsx"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`type ClientDetails = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  title: string;
};`}</code>{`
        `}</deckgo-highlight-code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      